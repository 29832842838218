import { ShowStudentStageViewProps } from "../../models";
import { Atom, state } from ":mods";
import { For, Show, createEffect, splitProps } from "solid-js";
import { getSubModuleRoute } from "../../methods";

export function ShowStudentStage(props: ShowStudentStageViewProps) {
  const [local, others] = splitProps(props, ["$", "details"]);
  const $details = state.createMemo(() => local.details);
  local.$.actions.setLayout({
    title: () => (
      <div class="text-20px font-700 leading-22px capitalize mb-20px">
        {$details()?.module_id && (
          <p class="font-400 mb-10px text-14px">
            Module {(parseInt($details()?.module_id as string) < 10 ? "0" : "") + $details()?.module_id}
          </p>
        )}

        <span class="font-bold">{$details()?.name}</span>
        {" - "}
        <span class="text#highlight">{`${$details()?.percentage || 0}% complete`}</span>
      </div>
    ),
    titleBorder: true,
  });

  return (
    <Show
      when={$details()}
      fallback={
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Modules"
            msg="Loading stage modules..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      }
    >
      <section class="text-16px mt-20px px-1">
        <div class="flex flex-col gap-10px mb-20px">
          <span class="font-bold">Content Introduction:</span>
          <span>
            <p>{$details()?.content_introduction ?? ""}</p>
          </span>
          <span class="font-bold">Topics:</span>
        </div>

        <section class="flex flex-col space-y-2">
          <For each={$details()?.submodule}>
            {(item, index) => {
              return (
                <Atom.Foldable.Accordion
                  title={
                    <section class="flex flex-row justify-between items-center w-full ml-20px">
                      <div class="font-700">
                        <span>{item.name}</span>
                        {" - "}
                        <span class="text#highlight">{`${item.percentage ?? 0}% complete`}</span>
                      </div>
                      <button
                        class="cursor-pointer underline text-14px"
                        onclick={() => {
                          const route = getSubModuleRoute(item.id);
                          local.$.actions.navigate(route);
                        }}
                      >
                        View topic
                      </button>
                    </section>
                  }
                  class="border-2px border-solid border#p  py-3 "
                  groupClass="px-4"
                  // groupClass=""
                  activeClass="border!highlight"
                >
                  <div id="summary-section" class="flex justify-between text-16px px-4">
                    <div class="flex gap-10px py-40px">
                      <i class="icon-local:video block w-25px h-25px" />
                      <p>{$details()?.total_video || ""}</p>
                    </div>
                    <div class="flex gap-10px py-40px">
                      <i class="icon-local:reading block w-25px h-25px" />
                      <p>{$details()?.total_reading || ""}</p>
                    </div>
                    <div class="flex gap-10px py-40px">
                      <i class="icon-local:quiz block w-25px h-25px" />
                      <p> {`${$details()?.total_questions || ""}`}</p>
                    </div>
                    <div class="flex gap-10px py-40px">
                      <i class="icon-local:task block w-25px h-25px" />
                      <p> {`${$details()?.total_tasks || ""}`}</p>
                    </div>
                  </div>
                  <div class="px-4">{$details()?.description || ""}</div>
                </Atom.Foldable.Accordion>
              );
            }}
          </For>
        </section>
      </section>
    </Show>
  );
}
